.blogsGrid {
  list-style-type: none;
  padding: 1rem;
  margin: 0;
  display: grid;
  gap: 1.5rem;

  @media screen and (min-width: 45em) {
    gap: 2rem;
    padding: 2rem;
  }

  @media screen and (min-width: 60em) {
    grid-template-columns: repeat(2, minmax(0, 1fr)); // Prevent "grid blowout"
    grid-auto-flow: column dense;
  }
}

.blog {
  display: contents;

  @media screen and (min-width: 60em) {
    &:nth-child(2n) {
      .gridItem {
        grid-column: 2;
      }
    }
  }
}

.articles {
  list-style-type: none;
  display: contents;
  margin: 0;
  padding: 0;
}

.blogHeader {
  text-align: center;
  font-size: 1.3em;
  margin: 0;
  margin-top: 1rem;
  border-bottom: 2px solid var(--gray-border);
  padding-bottom: 0.5rem;
}

.favicon {
  margin-right: 0.5rem;
}
